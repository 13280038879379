import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import './Button.css'; // Import your external CSS file

const Button = ({ isSubmitting, onClick }) => {
  const [clicked, setClicked] = useState(false);
  const [validated, setValidated] = useState(false);

  useEffect(() => {
    if (isSubmitting) {
      setClicked(true);
      setTimeout(() => {
        setClicked(false);
        setValidated(true);
        setTimeout(() => {
          setValidated(false);
        }, 1250);
      }, 2250);
    }
  }, [isSubmitting]);

  return (
    <div className="btnContainer">
      <button
        id="button"
        className={clicked ? 'onclic' : ''}
        onClick={onClick}
        disabled={isSubmitting}
      >
        {validated ? (
          <div>SUBMITTED <FontAwesomeIcon icon={faCheck} className='faIcon'/></div>
        ) : (
          'SUBMIT'
        )}
      </button>
    </div>
  );
};

export default Button;
