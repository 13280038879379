import React from 'react';
import './Footer.css'; // Import the CSS file for the footer styles
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLinkedinIn,faXTwitter, faInstagram } from '@fortawesome/free-brands-svg-icons';
import { faEnvelope } from '@fortawesome/free-regular-svg-icons';

const Footer = () => {
  // Get the current year dynamically
  const currentYear = new Date().getFullYear();

  return (
    <div className="footer-basic">
      <footer>
        <div className="social">
          <a href="https://www.instagram.com/robosmic?igsh=NDZzcXRwMjVtdmkx" className='reveal'><FontAwesomeIcon icon={faInstagram} /></a>
          <a href="#" className='reveal'><FontAwesomeIcon icon={faXTwitter} /></a>
          <a href="https://www.linkedin.com/company/robosmic/" className='reveal'><FontAwesomeIcon icon={faLinkedinIn} /></a>
          <a href="mailto:info@robosmic.in" className='reveal'><FontAwesomeIcon icon={faEnvelope} /></a>
        </div>
        <ul className="list-inline ">
          <li className="list-inline-item reveal"><a href="#home">Home</a></li>
          <li className="list-inline-item reveal"><a href="#product">Product</a></li>
          <li className="list-inline-item reveal"><a href="#about">About</a></li>
          <li className="list-inline-item reveal"><a href="#">Terms</a></li>
          <li className="list-inline-item reveal"><a href="#">Privacy Policy</a></li>
        </ul>
        <p className="copyright reveal">Robosmic © {currentYear}</p>
      </footer>
    </div>
  );
};

export default Footer;
