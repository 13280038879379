import React, { useState } from 'react';
import './navbar.css';
import logo from '../../assets/logo.jpg';
import NavButton from '../../containers/navButton/NavButton';

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleSidebar = () => {
    setIsOpen(!isOpen);
  };

  const closeSidebar = () => {
    setIsOpen(false);
  };

  return (
    <nav className="nav">
      <div className="logo">
        <a href='/'><img src={logo} alt="Logo" /></a>
      </div>
      <div className="nav-actions">
        <a href='#contact-us'><NavButton /></a>
        <div className={`menu-icon ${isOpen ? 'open' : ''}`} onClick={toggleSidebar}>
          <div></div>
          <div></div>
          <div></div>
        </div>
      </div>
      <div className={`sidebar ${isOpen ? 'open' : ''}`}>
        <div className='sidebar-link-menu'>MENU</div>
        <a href="#home" className="sidebar-link" onClick={closeSidebar}>Home</a>
        <a href="#use-cases" className="sidebar-link" onClick={closeSidebar}>Use Cases</a>
        <a href="#about" className="sidebar-link" onClick={closeSidebar}>About Us</a>
        <a href="#contact-us" className="sidebar-link" onClick={closeSidebar}>Contact Us</a>
      </div>
    </nav>
  );
};

export default Navbar;
