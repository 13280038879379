import React, { useState, useEffect } from 'react';
import CustomInput from '../../containers/input/Input';
import './contact.css'; // Custom styles for contact form
import emailjs from 'emailjs-com';
import Button from '../../containers/button/Button';
import Swal from 'sweetalert2';

const Contact = () => {
  const [email, setEmail] = useState('');
  const [fullName, setFullName] = useState('');
  const [message, setMessage] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isFormValid, setIsFormValid] = useState(false);

  useEffect(() => {
    emailjs.init(process.env.REACT_APP_EMAILJS_USER_ID);
  }, []);

  useEffect(() => {
    // Corrected regex for email validation
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,8}(\.[a-z]{2,8})?$/;

    const isEmailValid = (email) => {
      return emailRegex.test(email);
    };

    setIsFormValid(
      isEmailValid(email) && fullName.trim() !== '' && message.trim() !== ''
    );
  }, [email, fullName, message]);

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handleFullNameChange = (e) => {
    setFullName(e.target.value);
  };

  const handleMessageChange = (e) => {
    setMessage(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault(); // Prevent default form submission

    // Corrected regex for email validation
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,8}(\.[a-z]{2,8})?$/;

    const isEmailValid = (email) => {
      return emailRegex.test(email);
    };

    // Check if email is valid before submission
    if (!isEmailValid(email)) {
      Swal.fire({
        title: "Invalid Email Address",
        text: "Please enter a valid email address (e.g. yourname@gmail.com).",
        icon: "error",
        confirmButtonText: "OK",
        customClass: {
          confirmButton: 'alert-confirm-button',
          title: 'alert-title',
          popup: 'alert-popup'
        }
      });
      return; // Stop form submission if email is invalid
    }

    if (!isFormValid) {
      Swal.fire({
        title: "Form Incomplete",
        text: "Please fill out all fields before submitting.",
        icon: "error",
        confirmButtonText: "OK",
        customClass: {
          confirmButton: 'alert-confirm-button',
          title: 'alert-title',
          popup: 'alert-popup'
        }
      });
      return; // Stop submission if the form is incomplete
    }

    setIsSubmitting(true);
    const form = e.target;
    try {
      await emailjs.sendForm(
        process.env.REACT_APP_EMAILJS_SERVICE_ID,
        process.env.REACT_APP_EMAILJS_TEMPLATE_ID,
        form,
        process.env.REACT_APP_EMAILJS_USER_ID
      );

      setFullName('');
      setEmail('');
      setMessage('');

      Swal.fire({
        title: "Email Sent!",
        text: "Your message has been sent successfully.",
        icon: "success",
        confirmButtonText: "OK",
        customClass: {
          confirmButton: 'alert-confirm-button',
          title: 'alert-title',
          popup: 'alert-popup'
        }
      });
    } catch (error) {
      console.error('Error sending email:', error);
      Swal.fire({
        title: "Error",
        text: "There was an error sending your message. Please try again.",
        icon: "error",
        confirmButtonText: "OK",
        customClass: {
          confirmButton: 'alert-confirm-button',
          title: 'alert-title',
          popup: 'alert-popup'
        }
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <section id='contact-us reveal'>
      <div className="form-container reveal">
        <h1>Contact Us</h1>
        <form className="contact-form" onSubmit={handleSubmit} name="contact_form">
          <CustomInput
            label="Email Address"
            type="email"
            name="email"
            value={email}
            onChange={handleEmailChange}
          />
          
          <CustomInput
            label="Full Name"
            type="text"
            name="name"
            value={fullName}
            onChange={handleFullNameChange}
          />
          <CustomInput
            label="Message"
            type="textarea"
            name="message"
            value={message}
            onChange={handleMessageChange}
          />
          <div className="button-container reveal">
            <Button 
              isSubmitting={isSubmitting}
              type="submit"
            />
          </div>
        </form>
      </div>
    </section>
  );
};

export default Contact;
