import React, { useEffect, useRef } from 'react';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import usecase1 from "../../assets/industry.jpg";
import usecase2 from "../../assets/logistics_warehouses.jpg";
import usecase3 from "../../assets/parking.jpg";
import usecase4 from "../../assets/plane-train.jpg";
import UseCase from '../../containers/useCase/UseCase';
// import the other components 
import Contact from '../contact/Contact'; 
import Footer from '../footer/Footer';
import "./UseCases.css"

// to achieve horizontal scroll when scrolling vertically
gsap.registerPlugin(ScrollTrigger);


// use case details
const races = [
  {
    image: usecase1,
    title: "Industry - Manufacturing",
    description: "Enhance safety in industrial environments, including factories, warehouses, and chemical plants. Detect fire and smoke early to prevent large-scale disasters, ensuring the protection of workers and costly machinery.",
  },
  {
    image: usecase2,
    title: "Logistics - Warehouses",
    description: "Protect goods and inventory in logistics hubs and warehouses. Continuous monitoring helps in quick detection and response, reducing potential losses from fire incidents.",
  },
  {
    image: usecase3,
    title: "Parking - eMobility",
    description: "Monitor EV charging stations for potential fire hazards caused by electrical faults or battery malfunctions. Real-time detection of smoke and fire ensures quick response, preventing damage to vehicles and charging infrastructure.",
  },
  {
    image: usecase4,
    title: "Transportation - Airports\n & Railways",
    description: "Improve safety at airports, train stations, and bus terminals. Early detection of fire and smoke in these crowded environments helps in managing evacuations and maintaining public safety.",
  },
];

const UseCasesComponent = () => {
  const racesRef = useRef(null);

  useEffect(() => {
    const racesElement = racesRef.current;

    
    // horizontal scrolling amount
    const getScrollAmount = () => {
      // for mobiles
      if (window.innerWidth <= 768) {
        return -(racesElement.scrollWidth - window.innerWidth + 10);
      } else {
        // for desktops
        return -(racesElement.scrollWidth - window.innerWidth + 100);
      }
    };

    const tween = gsap.to(racesElement, {
      x: getScrollAmount(),
      duration: 3,
      ease: 'none',
    });

    ScrollTrigger.create({
      trigger: racesElement,
      start: 'top 20%',
      end: () => `+=${getScrollAmount() * -1}`,
      pin: true,
      animation: tween,
      scrub: 1,
      invalidateOnRefresh: true,
      markers: false,
      // trigger vertical scrolling after all usecases completed
      onLeave: () => {
        document.querySelector('.rest-section').style.display = 'block';
      }
    });

    return () => {
      ScrollTrigger.getAll().forEach((trigger) => trigger.kill());
    };
  }, []);

  return (
    <>
      <hr id='use-cases' />
      <section>
      <div >
      <div className='UseCaseHeading reveal'>
        <h2>Use</h2>
        <h1> Cases</h1>
      </div>
      <div className='space' height="10vh" />
      <div className='RaceWrapper'>
        <div className='Races' ref={racesRef}>
          {races.map((race, index) => (
            <UseCase
              key={index}
              title={race.title}
              description={race.description}
              image={race.image}
            />
          ))}
        </div>
      </div>
      {/* adding other components in the use cases section only such that they won't affect horizontal scrolling animation */}
      <div className="rest-section">
        <Contact />
        <Footer />
      </div>
    </div>
    </section>
    </>
  );
};

export default UseCasesComponent;