import React from 'react';
import 'ldrs/ripples';
import { ripples } from 'ldrs';
import "./loading.css"
ripples.register()

const Loading = () => {
  return (
    <div className="loading-container">
      <l-ripples
        size="180"
        speed="4" 
        color="white" 
      ></l-ripples>
    </div>
  );
}

export default Loading;
