import React from "react";
import "./hero.css"; // Import your CSS file for styling
import video from "../../assets/warehouse1.mp4"; // Import video file

const Hero = () => {
  return (
    <>
    <hr id="home"/>
      <section className="introductory">
        <div className="content-1">
          <div className="video-section">
            <div className="video-int">
              <video
                autoPlay
                loop
                muted
                src={video}
                className="intro-video"
                type="video/mp4"
              />
            </div>
          </div>
        </div>
        <div className="text-section">
          <h1>Ensure Safety Now</h1>
          <h2>Transform your existing CCTV footage into a powerful fire and smoke detection system with Robosmic’s cutting-edge technology.</h2>
        </div>
      </section>
      {/* add link for above section */}
      <hr id="about" />
    </>
  );
};

export default Hero;
