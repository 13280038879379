import React, { useState } from 'react';
import './input.css';

const CustomInput = ({ label, type, value, onChange,name }) => {
  const [focused, setFocused] = useState(false);

  const handleFocus = () => {
    setFocused(true);
  };

  const handleBlur = () => {
    if (value === '') {
      setFocused(false);
    }
  };

  const handleChange = (e) => {
    onChange(e);
    if (!focused && e.target.value !== '') {
      setFocused(true);
    }
  };

  return (
    <div className={`custom-input reveal ${focused || value !== '' ? 'focused' : ''}`}>
      {type === 'textarea' ? (
        <textarea
          value={value}
          onChange={handleChange}
          onFocus={handleFocus}
          onBlur={handleBlur}
          rows={4}
          name={name}
          required={true}
          autoComplete='off'
        ></textarea>
      ) : (
        <input
          type={type}
          value={value}
          onChange={handleChange}
          onFocus={handleFocus}
          onBlur={handleBlur}
          name={name}
          required={true}
          autoComplete='off'
        />
      )}
      <label>{label}</label>
    </div>
  );
};

export default CustomInput;
