import React from 'react';
import './usecase.css';
const UseCase = ({ title, description, image }) => {
  return (
    <div className="usecase">
      <div className="content">
        <h3 className="use-case-title">
          {title.split('\n').map((line, index) => (
            <span key={index}>
              {line}
              <br />
            </span>
          ))}
        </h3>
        <div className="image-container">
          <img src={image} alt={title} className="use-case-image" />
        </div>
        <p className="use-case-description">{description}</p>
      </div>
    </div>
  );
};

export default UseCase;
