import React from 'react';
import './why.css';

const WhyUs = () => {
  return (
    <>
      <section className='why'>
        <h1>Why Robosmic</h1>
        <h3>Robosmic offers a seamless integration of advanced fire and smoke detection technology into your existing CCTV infrastructure. Our state-of-the-art solution, deployed on AWS, ensures real-time monitoring and instant alerts, providing you with peace of mind and enhanced safety.<br /></h3>
        <div className='key-points'>
          <div className='card reveal'>
            <h4>Reliability</h4>
            <p>Robust detection algorithms with high accuracy rates.</p>
          </div>
          <div className='card reveal'>
            <h4>Scalability</h4>
            <p>Easily scalable to monitor multiple locations.</p>
          </div>
          <div className='card reveal'>
            <h4>Real-Time Alerts</h4>
            <p>Instant notifications to keep you informed.</p>
          </div>
          <div className='card reveal'>
            <h4>Cost-Efficiency</h4>
            <p>Utilize your existing CCTV setup without the need for new hardware.</p>
          </div>
          <div className='card reveal'>
            <h4>User-Friendly Interface</h4>
            <p>Intuitive portal for easy access and monitoring.</p>
          </div>
          <div className='card reveal'>
            <h4>Customizable Solutions</h4>
            <p>Tailored to meet your specific safety needs.</p>
          </div>
        </div>
      </section>
    </>
  );
}

export default WhyUs;
