import React, { useState, useEffect } from 'react';
import ScrollReveal from 'scrollreveal';
import Navbar from "../components/navbar/Navbar";
import Hero from '../components/hero-section/Hero';
import WhyUs from '../components/why_section/WhyUs';
// import Product from '../components/product-section/Product';
import UseCases from '../components/useCases/UseCases';
import Loading from '../components/loading/Loading';

function Home() {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 3000); // Display the loading animation for 3 seconds

    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    // add class hidden to remove unwanted scrolling
    if (isLoading) {
      document.body.classList.add('hidden');
    } else {
      document.body.classList.remove('hidden');
    }
    return () => {
      document.body.classList.remove('hidden');
    };
  }, [isLoading]);

  useEffect(() => {
    // Initialize ScrollReveal after the loading animation is done
    if (!isLoading) {
      ScrollReveal().reveal('.reveal', {
        origin: 'bottom',
        distance: '50px',
        duration: 1000,
        reset: true,
      });
    }
  }, [isLoading]);

  return (
    <div className="App">
      {isLoading && <Loading />} {/* Show loading animation if isLoading is true */}
      <div>
        <Navbar />
        <div className="reveal">
          <Hero />
        </div>
        <div className="reveal">
          <WhyUs />
        </div>
        {/* <Product /> */}
        <div>
          <UseCases />
        </div>
      </div>
    </div>
  );
}

export default Home;
