import React, { useState } from 'react';
import './NavButton.css'; // Import your CSS file for styling

const NavButton = () => {
  const [clicked, setClicked] = useState(false);

  const handleClick = () => {
    setClicked(true);
    setTimeout(() => {
      setClicked(false);
    }, 400); // Duration of the animation
  };

  return (
    <div className="buttons">
      <button className={`blob-btn ${clicked ? 'clicked' : ''}`} onClick={handleClick}>
        Book a demo
        <span className="blob-btn__inner">
          <span className="blob-btn__blobs">
            <span className="blob-btn__blob"></span>
            <span className="blob-btn__blob"></span>
            <span className="blob-btn__blob"></span>
            <span className="blob-btn__blob"></span>
          </span>
        </span>
      </button>
    </div>
  );
}

export default NavButton;
