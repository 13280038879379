import React from 'react';
import "./Error.css"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
const Error = () => {
  return (
    <div className="error-wrapper">
      <div className="error-container" data-text="404">
        <div className="title glitch" data-text="404">
          404
        </div>
        <div className="description glitch" data-text="PAGE NOT FOUND">
          PAGE NOT FOUND
        </div>
        <a href="/" className="back-home glitch"><FontAwesomeIcon icon={faArrowLeft} /> Go Home</a>
      </div>
    </div>
  );
};

export default Error;
